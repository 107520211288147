import React, { ReactChild } from 'react';
import ItemTile from '../item-tile';
import type { Tile } from '../item-tile';

interface Props {
  tiles: Tile[];
}

const TileGrid: React.FC<Props> = ({ tiles }) => {
  const components: ReactChild[] = [];

  tiles.forEach((tile) => {
    components.push(
      <ItemTile
        cy={tile.cy}
        key={tile.title}
        title={tile.title}
        description={tile.description}
        imageUrl={`https://cdn.volvotechinfo.com${tile.imageUrl}`}
        tileUrl={tile.tileUrl}
        target={tile.target ?? '_self'}
      />
    );
  });

  return (
    <React.Fragment>
      <div className="tile-grid">{components}</div>
      {/* language=CSS */}
      <style jsx>{`
        .tile-grid {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        @media screen and (min-width: 600px) {
          .tile-grid {
            flex-direction: row;
          }
        }

        @media screen and (max-width: 600px) {
          .tile-grid {
            flex-direction: column;
            align-items: center;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default TileGrid;
