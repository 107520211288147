import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const QUERY = gql`
  query messageBoard($accountId: String) {
    messageBoard(accountId: $accountId, expired: false) {
      id
      accountId
      name
      startDate
      endDate
      audience
      delta
    }
  }
`;

const useMessageBoard = (accountId: string) => {
  return useQuery(QUERY, {
    variables: { accountId },
  });
};

export default useMessageBoard;
