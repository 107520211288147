import React, { useState } from 'react';

interface Props {
  defaultValue: boolean;
  title: string;
  body: React.ReactChild;
  onClose?: () => void;
  padding?: string;
}

const Modal: React.FC<Props> = ({
  defaultValue,
  title,
  body,
  onClose = () => {},
  padding = '20em',
}) => {
  const [open, setOpen] = useState(defaultValue);

  const onClick = () => {
    setOpen(!open);
    onClose();
  };

  return (
    <React.Fragment>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-close" onClick={onClick}>
              &times;
            </span>
            <h2>{title}</h2>
          </div>
          <div className="modal-body">{body}</div>
        </div>
      </div>
      {/* language=CSS */}
      <style jsx>{`
        .modal {
          display: ${open ? 'block' : 'none'};
          position: fixed;
          z-index: 9999;
          padding-top: ${padding};
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: auto;
          /* fallback color */
          background-color: rgb(0, 0, 0);
          background-color: rgba(0, 0, 0, 0.4);
        }

        .modal-content {
          position: relative;
          background-color: #fefefe;
          margin: auto;
          padding: 0;
          border: 1px solid #888;
          width: 80%;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
          animation-name: animate-modal;
          animation-duration: 0.4s;
          overflow-y: scroll;
        }

        .modal-close {
          color: #fff;
          float: right;
          font-size: 28px;
          font-weight: 500;
        }

        .modal-close:hover,
        .modal-close:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }

        .modal-header {
          padding: 1rem 2rem;
          background-color: #707070;
          color: #fff;
        }

        .modal-header h2 {
          margin: 0;
          font-family: 'Volvo Novum', sans-serif;
          font-weight: 500;
        }

        .modal-body {
          padding: 1rem 2rem;
        }

        .modal-body p {
          font-family: 'Volvo Novum', sans-serif;
          font-size: 14px;
        }

        @keyframes animate-modal {
          from {
            top: -300px;
            opacity: 0;
          }
          to {
            top: 0;
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export default Modal;
