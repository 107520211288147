import React from 'react';
import MarkdownIt from 'markdown-it';

interface Props {
  markdownText: string;
}

const Parser = new MarkdownIt({ html: false, linkify: false });

const Markdown: React.FC<Props> = ({ markdownText }) => {
  return (
    <div dangerouslySetInnerHTML={{ __html: Parser.render(markdownText) }} />
  );
};

export default Markdown;
