import React from 'react';
import Link from 'next/link';

interface Tile {
  title: string;
  imageUrl: string;
  tileUrl: string;
  target?: string;
  description?: string;
  cy?: string;
}

const ItemTile: React.FC<Tile> = ({
  title,
  description,
  imageUrl,
  tileUrl,
  target,
  cy,
}) => (
  <React.Fragment>
    <div className="item-tile">
      <Link href={tileUrl}>
        <a target={target}>
          <div className="tile-content" data-cy={cy}>
            <figure>
              <img src={imageUrl} alt={title} className="tile-image" />
            </figure>
            <span className="tile-title">{title}</span>
            <span className="tile-description">{description}</span>
          </div>
        </a>
      </Link>
    </div>
    {/* language=CSS */}
    <style jsx>{`
      a {
        text-decoration: none;
      }
      .item-tile {
        display: block;
        width: 32%;
        margin-bottom: 3%;
        position: relative;
      }

      .tile-content {
        max-width: 256px;
        margin: 0 auto;
        text-align: center;
        cursor: pointer;
      }

      .tile-content > figure {
        margin: 0;
      }

      .tile-image {
        width: 100%;
        height: 144px;
        object-fit: cover;
      }

      .tile-title {
        font-family: 'Volvo Novum', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #707070;
        text-transform: uppercase;
        margin: 0.3rem 0;
        display: block;
      }

      .tile-description {
        font-family: 'Volvo Novum', sans-serif;
        font-weight: 200;
        font-size: 12px;
        color: #707070;
        margin: 0.3rem 0;
        display: block;
      }

      @media screen and (max-width: 1024px) {
        .item-tile {
          width: 49%;
        }
      }
    `}</style>
  </React.Fragment>
);

export default ItemTile;
export type { Tile };
