import React from 'react';
import useMessageBoard from '../../hooks/use-message-board';
import Modal from '../modal';
import useStickyState from '../../hooks/use-sticky-state';
import Markdown from '../@markdown/markdown';

const HomeMessageboard: React.FC = () => {
  if (typeof window === 'undefined') return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [importantMessage, setImportantMessage] = useStickyState(
    true,
    'volvoImportantMessage'
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, loading, error } = useMessageBoard(process.env.SITE!);

  if (loading || error) return null;
  if (!data) return null;

  const popoutBoard = data.messageBoard.filter(
    (m) =>
      JSON.parse(m.delta)?.options?.find((d) => d.name !== 'vida')?.value ===
        true &&
      JSON.parse(m.delta)?.options?.find((d) => d.name === 'modal')?.value ===
        true
  );

  if (popoutBoard.length === 0) return null;

  const openModal = () => {
    setImportantMessage(true);
  };

  return (
    <React.Fragment>
      {popoutBoard.length > 0 && (
        <React.Fragment>
          <div className="important-messages" onClick={openModal}>
            There are currently {popoutBoard.length} important{' '}
            {popoutBoard.length > 1 ? 'messages' : 'message'}. Click here to
            view them.
          </div>
          <Modal
            defaultValue={importantMessage}
            onClose={() => setImportantMessage(false)}
            key={'importantMessage' + importantMessage}
            title={'Important Message'}
            body={popoutBoard.map((mb, index) => {
              const title = mb.name;
              const text = JSON.parse(mb.delta).blocks[0].text;

              return (
                <div
                  className="popout-message"
                  key={index}
                  style={{ paddingBottom: '1rem' }}
                >
                  <h3 style={{ fontWeight: 500, margin: 0 }}>{title}</h3>
                  <Markdown markdownText={text} />
                </div>
              );
            })}
          />
        </React.Fragment>
      )}
      {/* language=CSS */}
      <style jsx>{`
        .important-messages {
          padding: 1rem;
          background-color: #707070;
          width: 100%;
          color: #fff;
          font-family: 'Volvo Novum', sans-serif;
          font-size: 14px;
          margin-bottom: 2rem;
          cursor: pointer;
        }

        .popout-message:not(:last-child) {
          margin-bottom: 2rem;
        }

        .popout-message {
          font-family: 'Volvo Novum', sans-serif;
        }

        .popout-message h3,
        .popout-message p {
          margin: 0;
          padding: 0;
        }

        .popout-message h3 {
          font-weight: 500;
          font-size: 16px;
        }
      `}</style>
    </React.Fragment>
  );
};

export default HomeMessageboard;
